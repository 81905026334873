import colleges from "../images/colleges.svg"
import collegePrediction from "../images/collegePrediction.svg"
import prediction from "../images/prediction.svg"
import rank from "../images/ranks.svg"
import seats from "../images/seats.svg"
import testChoices from "../images/testChoices.svg"
import pdf from "../images/pdf.svg"

export const featuresCard = [
	{
		title: "Participating Colleges",
		image: colleges,
		link: "/colleges_list",
	},
	{
		title: "Seat Matrix",
		image: seats,
		link: "/seat_matrix",
	},
	{
		title: "Opening and Closing Ranks",
		image: rank,
		link: "/rank",
	},
	{
		title: "Predict Your College",
		image: collegePrediction,
		link: "/predict_college",
	},
	{
		title: "Prediction Matrix",
		image: prediction,
		link: "/prediction",
	},
	{
		title: "Test Your JoSAA Choices",
		image: testChoices,
		link: "/choices",
	},
	{
		title: "Important Documents",
		image: pdf,
		link: "/pdfs",
	},
]

export const impDates = [
	{
		id: 1,
		title: "Registration for JEE (Advanced) 2023",
		startDate: "2023-04-30",
		endDate: "2023-05-07",
	},
	{
		id: 2,
		title: "Last date for fee payment of registered candidates",
		startDate: "2023-05-08",
		endDate: null,
	},
	{
		id: 3,
		title: "Admit card available for download",
		startDate: "2023-05-29",
		endDate: "2023-06-04",
	},
	{
		id: 4,
		title: "Choosing of scribe for PwD candidates",
		startDate: "2023-06-03",
		endDate: null,
	},
	{
		id: 5,
		title: "JEE (Advanced) 2023 Exam",
		startDate: "2023-06-04",
		endDate: null,
	},
	{
		id: 6,
		title:
			"Copy of candidate responses to be available on the JEE (Advanced) 2023 website",
		startDate: "2023-06-09",
		endDate: null,
	},
	{
		id: 7,
		title: "Online display of provisional answer keys",
		startDate: "2023-06-11",
		endDate: null,
	},
	{
		id: 8,
		title:
			"Feedback and comments on provisional answer keys from the candidates",
		startDate: "2023-06-11",
		endDate: "2023-06-12",
	},
	{
		id: 9,
		title: "Online declaration of final answer keys",
		startDate: "2023-06-18",
		endDate: null,
	},
	{
		id: 10,
		title: "Result of JEE (Advanced) 2023",
		startDate: "2023-06-18",
		endDate: null,
	},
	{
		id: 11,
		title: "Online registration for Architecture Aptitude Test (AAT) 2023.",
		startDate: "2023-06-18",
		endDate: "2023-06-19",
	},
	{
		id: 12,
		title: "Candidate registration/choice filling for JoSAA 2023 starts.",
		startDate: "2023-06-19",
		endDate: null,
	},
	{
		id: 13,
		title: "Display of Mock Seat Allocation-1 based on the choices filled.",
		startDate: "2023-06-25",
		endDate: null,
	},
	{
		id: 14,
		title: "Display of Mock Seat Allocation-2 based on the choices filled.",
		startDate: "2023-06-27",
		endDate: null,
	},
	{
		id: 15,
		title: "Candidate registration and choice filling for JoSAA 2023 ends.",
		startDate: "2023-06-25",
		endDate: null,
	},
	{
		id: 16,
		title: "Seat Allocation Round 1",
		startDate: "2023-06-30",
		endDate: null,
	},
	{
		id: 17,
		title: "Seat Allocation Round 2",
		startDate: "2023-07-06",
		endDate: null,
	},
	{
		id: 18,
		title: "Seat Allocation Round 3",
		startDate: "2023-07-12",
		endDate: null,
	},
	{
		id: 19,
		title: "Seat Allocation Round 4",
		startDate: "2023-07-16",
		endDate: null,
	},
	{
		id: 20,
		title: "Seat Allocation Round 5",
		startDate: "2023-07-21",
		endDate: null,
	},
	{
		id: 21,
		title: "Seat Allocation Round 6",
		startDate: "2023-07-26",
		endDate: null,
	},
	{
		id: 22,
		title: "Registration",
		startDate: "2023-11-01",
		endDate: "2023-12-04",
	},
	{
		id: 23,
		title: "JEE Mains Session-1",
		startDate: "2024-01-24",
		endDate: "2024-02-01",
	},
	{
		id: 24,
		title: " Results JEE Mains Session-1",
		startDate: "2024-02-12",
		endDate: null,
	},
	{
		id: 25,
		title: "Online submission of application form (Session-2)",
		startDate: "2024-02-02",
		endDate: "2024-03-02",
	},
	{
		id: 26,
		title:
			"Last date for successful transaction of Application Fee (Session-2)",
		startDate: "2024-02-02",
		endDate: "2024-03-02",
	},
	{
		id: 27,
		title: "JEE Mains Session-2",
		startDate: "2024-04-01",
		endDate: "2024-04-15",
	},
	{
		id: 28,
		title: " Results JEE Mains Session-2",
		startDate: "2024-04-25",
		endDate: null,
	},
	{
		id: 29,
		title: "Results JEE Advanced",
		startDate: "2024-06-09",
		endDate: null
	},
	{
		id:30,
		title: "Candidate registration/choice filling for academic programs under JoSAA STARTS.",
		startDate: "2024-06-10",
		endDate: null
	},
	{
		id:31,
		title: "Pre-payment of the JoSAA seat acceptance fee starts",
		startDate: "2024-06-10",
		endDate: null
	},
	{
		id: 32,
		title: "Candidates who qualify for the Architecture Aptitude Test (AAT) can fill in their AAT-specific choices",
		startDate: "2024-06-14",
		endDate: null
	},
	{
		id: 33,
		title: "Display of Mock Seat Allocation-1 based on the choices filled.",
		startDate: "2024-06-15",
		endDate: null
	},
	{
		id: 34,
		title: "Display of Mock Seat Allocation-2 based on the choices filled.",
		startDate: "2024-06-17",
		endDate: null
	},
	{
		id: 35,
		title: "Candidate registration and choice filling for JoSAA 2024 ends.",
		startDate: "2024-06-18",
		endDate: null
	},
	{
		id: 36,
		title: "Pre-payment of the JoSAA seat acceptance fee ends",
		startDate: "2024-06-18",
		endDate: null
	},
	{
		id: 37,
		title: "Seat Allocation Round 1",
		startDate: "2024-06-20",
		endDate: null
	},
	{
		id: 38,
		title: "Last date for fee payment (Round 1)",
		startDate: "2024-06-24",
		endDate: null
	},
	{
		id: 39,
		title: "Seat Allocation Round 2",
		startDate: "2024-06-27",
		endDate: null
	},
	{
		id: 40,
		title: "Last date for fee payment (Round 2)",
		startDate: "2024-07-01",
		endDate: null
	},
	{
		id: 41,
		title: "Seat Allocation Round 3",
		startDate: "2024-07-04",
		endDate: null
	},
	{
		id: 42,
		title: "Last date for fee payment (Round 3)",
		startDate: "2024-07-08",
		endDate: null
	},
	{
		id: 43,
		title: "Seat Allocation Round 4",
		startDate: "2024-07-10",
		endDate: null
	},
	{
		id: 44,
		title: "Last date for fee payment (Round 4)",
		startDate: "2024-07-15",
		endDate: null
	},
	{
		id: 45,
		title: "Seat Allocation Round 5",
		startDate: "2024-07-17",
		endDate: null
	},
	{
		id: 46,
		title: "Last date for fee payment (Round 5)",
		startDate: "2024-07-22",
		endDate: null
	},
]

export const websites = [
	{
		name: "JOSAA",
		val: "https://josaa.nic.in/",
	},
	{
		name: "CSAB",
		val: "https://csab.nic.in/",
	},
	{
		name: "JEE Mains",
		val: "https://jeemain.nta.nic.in/",
	},
	{
		name: "JEE Advanced",
		val: "https://jeeadv.ac.in/",
	},
]

export const PdfsList = [
	{
		id: 1,
		title: "JEE Advanced 2023 Paper 1",
		link: "https://jeeadv.ac.in/documents/JEEAdv2023_Paper1.pdf",
	},
	{
		id: 2,
		title: "JEE Advanced 2023 Paper 2",
		link: "https://jeeadv.ac.in/documents/JEEAdv2023_Paper2.pdf",
	},
	{
		id: 3,
		title: "Information bulletin for JEE Mains 2024",
		link: "https://jeemain.nta.ac.in/images/information-bulletin-for-jee-main-2024.pdf",
	},
	{
		id: 4,
		title: "Syllabus JEE Mains 2024",
		link: "https://jeemain.nta.ac.in/images/syllabus-for-jee-main-2024-as-on-01-november-2023.pdf",
	},
]
