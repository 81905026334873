export const filter_choices={
    STATE_CHOICES:[
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jammu & Kashmir ',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Orissa',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal',
        'Andaman and Nicobar Islands',
        'Chandigarh','Chandigarh',
        'Dadra and Nagar Haveli',
        'Daman and Diu',
        'Lakshadweep',
        'National Capital Territory of Delhi',
        'Puducherry',
    ],
    CATEGORY_CHOICES:["GEN-EWS(PwD)","GEN-EWS","General PwD","General","SC","ST","SC PwD","ST PwD","OBC-NCL","OBC-NCL(PwD)"],
    QUOTA_CHOICES:['AI','HS','OS','GO','JK','LA','AP'],
    SEAT_POOL_CHOICES:['Gender-Neutral','Female-only'],
   
    DEGREE_CHOICES:[
        "B. Tech / B. Tech (Hons.)",                               
        "B.Tech. + M.Tech./MS (Dual Degree)",                      
        "Bachelor and Master of Pharmaceutics(Dual Degree)",       
        "Bachelor and Master of Technology (Dual Degree)",        
        "Bachelor of Architecture",                               
        "Bachelor of Design",                                      
        "Bachelor of Pharmaceutics",                               
        "Bachelor of Planning",                                    
        "Bachelor of Science",                                     
        "Bachelor of Science and Master of Science (Dual Degree)", 
        "Bachelor of Technology",                                 
        "Bachelor of Technology and MBA (Dual Degree)",            
        "Integrated B. Tech. and M. Tech. /MBA",                   
        "Integrated Master of Science",                            
        "Integrated Master of Technology",                        
        "Integrated Masters in Technology",                       
    ],
    DURATION_CHOICES:[
        "4 Years",
        "5 Years",
    ]


}
