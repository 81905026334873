export const validateData = (props) => {
	let error = 0
	props.text.map((data) => {
		if (data === "" || data.length === 0) {
			error = 1
		}
	})
	props.number.map((data) => {
		if (data === 0) {
			error = 1
		}
	})

	if (error === 1) return false

	return true
}
