import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Header } from "../../../components/header"
import FormDialog from "../../../components/formDialog"
import { CollegePredictor, toastDuration } from "../../../constants/general"
import { connect } from "react-redux"
import { fetchCollegePredictor } from "../../../store/actions/prediction"
import { makeSelectCollegePredictor } from "../../../store/selectors/prediction"
import {
	Button,
	Checkbox,
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material"
import { choicesHeader } from "../../../constants/tableHeader"
import { useDispatch } from "react-redux"
import { showToast } from "../../../store/actions/toast"

const PredictCollege = ({ predictionComponent, predictionObj }) => {
	const [openForm, setOpenForm] = useState(true)
	const [category, setcategory] = useState("")
	const [cutoff, setcutoff] = useState(10)
	const [seatPool, setseatPool] = useState("")
	const [state, setState] = useState("")
	const [rank, setrank] = useState(0)
	const [rankMain, setRankMain] = useState(0)
	const [collegeLength, setCollegeLength] = useState(25)
	const [dataSubmit, setdataSubmit] = useState(false)
	const [selectAll, setSelectAll] = useState(false)
	const [showAllCheckboxes, setShowAllCheckboxes] = useState(false)
	const [selectedChoices, setSelectedChoices] = useState([])

	const dispatch = useDispatch()

	useEffect(() => {
		if (dataSubmit) {
			const payload = {
				rank,
				rankMain,
				category,
				seatPool,
				state,
				collegeLength,
				cutoff,
			}
			predictionComponent(payload)
			localStorage.setItem("category", category)
			localStorage.setItem("cutoff", cutoff)
			localStorage.setItem("seatPool", seatPool)
			localStorage.setItem("state", state)
			localStorage.setItem("rank", rank)
			localStorage.setItem("rankMain", rankMain)
			localStorage.setItem("collegeLength", collegeLength)
			setdataSubmit(false)
		}
	}, [dataSubmit])

	useEffect(() => {
		if (selectedChoices.length > 0) {
			if (selectedChoices.length !== predictionObj.data.length) {
				setSelectAll(false)
			}
			setShowAllCheckboxes(true)
		} else {
			setShowAllCheckboxes(false)
		}
	}, [selectedChoices])

	const editDetailButtonClick = () => {
		setOpenForm(true)
	}

	const handleAddChoice = (institute_id, branch_id, quota) => {
		let modifiedarray =
			JSON.parse(localStorage.getItem("saveTestChoices")) || []
		let modifiedObject = {
			institute_id: institute_id,
			branch_id: branch_id,
			quota: quota,
			seat_pool: seatPool,
			category: category,
			id: `${institute_id}_${branch_id}_${quota}_${category}_${seatPool}`,
		}
		if (
			modifiedarray.some(
				(obj) =>
					obj.id ===
					`${institute_id}_${branch_id}_${quota}_${category}_${seatPool}`
			)
		) {
			modifiedarray.push(modifiedObject)
			localStorage.setItem("saveTestChoices", JSON.stringify(modifiedarray))
		}
	}

	const selectAllCheckboxOnChange = (event) => {
		setSelectAll(event.target.checked)
		if (event.target.checked) {
			setSelectedChoices(predictionObj.data)
		} else {
			setSelectedChoices([])
		}
	}

	const handleRowClick = (data) => {
		setSelectedChoices((prevItems) => {
			const itemExists = prevItems.some((item) => item.id === data.id)

			if (itemExists) {
				// Remove the item if it exists
				return prevItems.filter((item) => item.id !== data.id)
			} else {
				// Add the item if it does not exist
				return [...prevItems, data]
			}
		})
	}

	const handleCopyToJoSAAList = () => {
		let modifiedarray =
			JSON.parse(localStorage.getItem("saveTestChoices")) || []
		selectedChoices.forEach((choice) => {
			const institute_id = choice.institute_detail.id
			const branch_id = choice.branch_detail.id
			const quota = choice.quota
			let modifiedObject = {
				institute_id: institute_id,
				branch_id: branch_id,
				quota: quota,
				seat_pool: seatPool,
				category: category,
				id: `${institute_id}_${branch_id}_${quota}_${category}_${seatPool}`,
			}
			if (
				modifiedarray.indexOf(
					(obj) =>
						obj.id ===
						`${institute_id}_${branch_id}_${quota}_${category}_${seatPool}`
				) === -1
			) {
				modifiedarray.push(modifiedObject)
			}
			localStorage.setItem("saveTestChoices", JSON.stringify(modifiedarray))
		})
		dispatch(
			showToast(
				'Your list has been successfully moved to the JoSAA List. You can view it in the "Test Your JoSAA Choices" section.',
				"success",
				toastDuration * 2
			)
		)
	}

	return (
		<div className='list-container'>
			<Helmet>
				<title>Rank Matrix | College Predictor</title>
				<meta
					name='keywords'
					content='Predict College, Predict Branch, College Predictor, Engineering Predictor, Predictor, predictor'
				/>
			</Helmet>
			<Header heading='College Predictor' />
			<FormDialog
				openForm={openForm}
				setopenForm={setOpenForm}
				predictionData={CollegePredictor}
				setCategory={setcategory}
				setCutoff={setcutoff}
				setSeatPool={setseatPool}
				setState={setState}
				setRank={setrank}
				setMainsRank={setRankMain}
				setCollegeLength={setCollegeLength}
				setdataSubmit={setdataSubmit}
			/>

			<div className='table-container'>
				<div className='filters between'>
					<Button className='choice-button' onClick={editDetailButtonClick}>
						Edit Details
					</Button>
					{selectedChoices.length > 0 && (
						<Button
							variant='filled'
							className='josaa-list-button'
							onClick={handleCopyToJoSAAList}
						>
							Copy to JoSAA List
						</Button>
					)}
				</div>
				{predictionObj.loading ? (
					<CircularProgress />
				) : (
					!predictionObj.error &&
					predictionObj.data.length !== 0 && (
						<>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }}>
									<TableHead>
										<TableRow>
											<TableCell
												className='noto-sans tablehead-checkbox-column'
												align='center'
											>
												<Checkbox
													checked={selectAll}
													onChange={selectAllCheckboxOnChange}
													disabled={!showAllCheckboxes}
													className={
														showAllCheckboxes
															? "active-tablehead-checkbox"
															: "inactive-tablehead-checkbox"
													}
												/>
											</TableCell>
											{choicesHeader.map((header, index) => (
												<TableCell key={index}>{header.label}</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{predictionObj.data.map((data, index) => (
											<TableRow
												key={index}
												onClick={() => handleRowClick(data)}
											>
												<TableCell>
													<Checkbox
														checked={selectedChoices.indexOf(data) !== -1}
														className={
															selectAll || selectedChoices.indexOf(data) !== -1
																? "active-checkbox"
																: "inactive-checkbox"
														}
													/>
												</TableCell>
												<TableCell>{data.institute_detail.type}</TableCell>
												<TableCell>{data.institute_detail.full_name}</TableCell>
												<TableCell>{data.branch_detail.full_name}</TableCell>
												<TableCell>{data.category}</TableCell>
												<TableCell>{data.quota}</TableCell>
												<TableCell>{data.seat_pool}</TableCell>
												<TableCell>{data.opening_rank}</TableCell>
												<TableCell>{data.closing_rank}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					)
				)}
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		predictionObj: makeSelectCollegePredictor(state),
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		predictionComponent: (payload) => dispatch(fetchCollegePredictor(payload)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PredictCollege)
